export const ValueMaxInput = ({ nodeStatusInfo, callback }) => {
    return (
        <input
            type="number"
            disabled={!nodeStatusInfo.range}
            value={nodeStatusInfo.valueMax}
            className="w-14 h-10 mr-5"
            onChange={e => {
                callback(e.target.value, 'valueMax');
            }}
        />
    );
};
