export const RangeCheckbox = ({ nodeStatusInfo, callback }) => {
    return (
        <div className="w-14 mr-3 flex mt-3">
            <input
                disabled={!nodeStatusInfo.node || nodeStatusInfo.type === 'boolean'}
                className="block ml-auto mr-auto cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={nodeStatusInfo.range}
                checked={nodeStatusInfo.range}
                onChange={() => {}}
                onClick={e => callback('range', e.target.checked)}
            />
        </div>
    );
};
