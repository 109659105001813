export const PopupHead = ({ nodeStates }) => {
    const range = nodeStates.find(el => el.range);
    return (
        <div className="flex mt-5 items-center">
            <span className="w-16 mr-5">Статус</span>
            <span className="w-12 mr-5">Фото</span>
            <span className="w-48 mr-3">Выбор точки</span>
            <span className="w-14 mr-3 break-all text-center">Бит</span>
            <span className="w-14 mr-3 break-all text-center">Диапазон</span>

            <span className="w-24 mr-5 text-center">Значение {range && '/ min'}</span>
            <span className="w-14 mr-5 text-center">max</span>
            <span className="w-40 text-center">Наименование точки</span>
        </div>
    );
};
