export const RemoveStatusButton = ({ setNodeStates, node }) => {
    return (
        <button
            type="button"
            className="ml-2 rounded-full hover:bg-red-500 w-5 h-5 mt-3 transition-colors"
            onClick={() => {
                setNodeStates(prev => prev.filter(el => el.id !== node.id));
            }}
        >
            <svg width="18" height="18" viewBox="0 0 18 18" className="fill-black transition-colors">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9C3 8.58579 3.33579 8.25 3.75 8.25Z"
                />
            </svg>
        </button>
    );
};
