export const ValueInput = ({ nodeStatusInfo, callback }) => {
    return (
        <input
            type="number"
            value={nodeStatusInfo.value}
            disabled={!nodeStatusInfo.node || nodeStatusInfo.bit}
            className="w-24 h-10 mr-5"
            onChange={e => {
                callback(e.target.value, 'value');
            }}
        />
    );
};
