import { useState, useEffect } from 'react';

import { BitPopup } from '../BitPopup';
import { DataPointSelect } from './DataPointSelect';
import { DataPointPicture } from './DataPointPicture';
import { BitCheckbox } from './BitCheckbox';
import { RangeCheckbox } from './RangeCheckbox';
import { EditBitValueButton } from './EditBitValueButton';
import { ValueInput } from './ValueInput';
import { BooleanValueSelect } from './BooleanValueSelect';
import { ValueMaxInput } from './ValueMaxInput';
import { TitleInput } from './TitleInput';

const initState = { node: null, title: '', range: false, value: '', valueMax: '', bit: false, bitValue: '', operation: '=' };

export const NodeStatusForm = ({ setCheckPopupFields, nodeOptions, node, nodeStates, setNodeStates, statusInfo, isUseWithoutDataPoint }) => {
    const [nodeStatusInfo, setNodeStatusInfo] = useState(statusInfo ? statusInfo : initState);
    const [selectValue, setSelectValue] = useState([]);
    const [bitPopupIsOpen, setBitPopupIsOpen] = useState(false);

    useEffect(() => {
        if (isUseWithoutDataPoint) {
            setSelectValue([]);
            setNodeStatusInfo(initState);
            setNodeStates([]);
        }
    }, [isUseWithoutDataPoint]);

    useEffect(() => {
        if (statusInfo && nodeOptions && !isUseWithoutDataPoint) {
            setSelectValue(nodeOptions.find(el => el.value === statusInfo.node));
        }
    }, [statusInfo, nodeOptions]);

    useEffect(() => {
        if (selectValue && selectValue.datatype === 'boolean') {
            setNodeStatusInfo({
                ...nodeStatusInfo,
                type: selectValue.datatype,
                value: statusInfo.value ? (statusInfo.value === 'true' ? { value: 1, label: 'true' } : { value: 0, label: 'false' }) : '',
            });
        }
    }, [selectValue]);

    useEffect(() => {
        for (const el of nodeStates) {
            if (el.node && el.node.length !== 0 && el.title.length !== 0) {
                if (!el.bit && !el.range) {
                    if (el.value?.length === 0) {
                        return setCheckPopupFields(false);
                    } else {
                        setCheckPopupFields(true);
                    }
                } else if (el.bit) {
                    if (el.bitValue.length === 0) {
                        return setCheckPopupFields(false);
                    } else {
                        setCheckPopupFields(true);
                    }
                } else if (el.range) {
                    if (el.value.length === 0 || el.valueMax.length === 0) {
                        return setCheckPopupFields(false);
                    } else {
                        setCheckPopupFields(true);
                    }
                }
            } else {
                return setCheckPopupFields(false);
            }
        }
    }, [nodeStates]);

    const handlerInputValueChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (nodeStatusInfo.node.datatype === 'float') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (nodeStatusInfo.node.datatype === 'int') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setNodeStatusInfo({ ...nodeStatusInfo, [type]: floatCheck[0] });
                setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, [type]: floatCheck[0] } : el)));
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setNodeStatusInfo({ ...nodeStatusInfo, [type]: value[1] });
            setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, [type]: value[1] } : el)));
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setNodeStatusInfo({ ...nodeStatusInfo, [type]: val });
            setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, [type]: val } : el)));
            return;
        }

        setNodeStatusInfo({ ...nodeStatusInfo, [type]: value });
        setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, [type]: value } : el)));
    };

    const handlerDataPointSelect = val => {
        if (val === null) {
            return;
        }
        setNodeStatusInfo({
            ...nodeStatusInfo,
            ...initState,
            node: val,
            type: val.datatype,
            bitsize: val.bitsize,
        });
        setNodeStates(() => {
            if (nodeStates.find(el => el.name === node.name)) {
                return nodeStates.map(el =>
                    el.name === node.name
                        ? {
                              ...initState,
                              node: val.value,
                              name: node.name,
                              pic: node.pic,
                              type: val.datatype,
                              bitsize: val.bitsize,
                          }
                        : el
                );
            } else {
                return [
                    ...nodeStates,
                    {
                        ...initState,
                        node: val.value,
                        name: node.name,
                        pic: node.pic,
                        type: val.datatype,
                        bitsize: val.bitsize,
                    },
                ];
            }
        });
    };

    const handlerCheckboxChange = (type, val) => {
        if (type === 'bit') {
            setNodeStatusInfo({ ...nodeStatusInfo, [type]: val, value: '', valueMax: '', range: false, bitValue: '' });
            setNodeStates(() =>
                nodeStates.map(el => (el.name === node.name ? { ...el, [type]: val, value: '', valueMax: '', range: false, bitValue: '' } : el))
            );
            if (val) {
                setBitPopupIsOpen(true);
            } else {
                setBitPopupIsOpen(false);
            }
        } else {
            setNodeStatusInfo({ ...nodeStatusInfo, [type]: val, value: '', valueMax: '', bit: false, bitValue: '' });
            setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, [type]: val, value: '', valueMax: '', bit: false, bitValue: '' } : el)));
        }
    };

    const handlerBooleanValueChange = val => {
        setNodeStatusInfo({ ...nodeStatusInfo, val });
        setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, value: val.label } : el)));
    };

    const handlerTitleChange = val => {
        setNodeStatusInfo({ ...nodeStatusInfo, title: val });
        setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, title: val } : el)));
    };

    const handlerBitValueChange = (type, val) => {
        if (type === 'setValue') {
            setNodeStatusInfo({ ...nodeStatusInfo, bitValue: val });
            setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, bitValue: val } : el)));
        } else {
            setNodeStatusInfo({ ...nodeStatusInfo, bit: false });
            setNodeStates(() => nodeStates.map(el => (el.name === node.name ? { ...el, bit: false } : el)));
        }
    };

    return (
        <>
            <span className="w-16 mr-5 mt-2"> {node.name} </span>
            {node.pic ? <DataPointPicture node={node} /> : <div className="w-12 h-12 mr-5"></div>}
            <DataPointSelect
                callback={handlerDataPointSelect}
                nodeOptions={nodeOptions}
                selectValue={selectValue}
                isUseWithoutDataPoint={isUseWithoutDataPoint}
            />

            {/* bit */}
            <BitCheckbox nodeStatusInfo={nodeStatusInfo} callback={handlerCheckboxChange} />

            {/* range */}
            <RangeCheckbox nodeStatusInfo={nodeStatusInfo} callback={handlerCheckboxChange} />

            {/* value/min */}
            {nodeStatusInfo?.type !== 'boolean' ? (
                nodeStatusInfo.bit ? (
                    <EditBitValueButton setBitPopupIsOpen={setBitPopupIsOpen} />
                ) : (
                    <ValueInput nodeStatusInfo={nodeStatusInfo} callback={handlerInputValueChange} />
                )
            ) : (
                <BooleanValueSelect nodeStatusInfo={nodeStatusInfo} callback={handlerBooleanValueChange} />
            )}

            {/* value max */}
            <ValueMaxInput nodeStatusInfo={nodeStatusInfo} callback={handlerInputValueChange} />

            {/* title */}
            <TitleInput nodeStatusInfo={nodeStatusInfo} callback={handlerTitleChange} />

            {/* bits popup */}
            {bitPopupIsOpen && <BitPopup nodeStatusInfo={nodeStatusInfo} setBitPopupIsOpen={setBitPopupIsOpen} callback={handlerBitValueChange} />}
        </>
    );
};
